#boldsign-iframe {
  border: 0;
  height: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-device-height: 600px) {
  #boldsign-iframe {
    min-height: 300px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-device-height: 600px) {
  #boldsign-iframe {
    min-height: 400px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-device-height: 768px) {
  #boldsign-iframe {
    min-height: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-device-height: 992px) {
  #boldsign-iframe {
    min-height: 600px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-device-height: 1200px) {
  #boldsign-iframe {
    min-height: 1000px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-device-height: 1500px) {
  #boldsign-iframe {
    min-height: 1200px;
  }
}