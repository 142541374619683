#container {
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 480px) {
  #container {
    width: 82%;
  }
}

#header {
  height: 40px;
}

#footer {
  height: 40px;
}

#editor-container {
  height: 100%;
  /* added these styles */
  flex: 1;
  display: flex; 
  flex-direction: column;
}

#editor {
  height: 100%;
  
  /* added these styles */
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.ql-editor {
  min-height: 200px;
}